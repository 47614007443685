export default {
	//存放数据的
	state: {
		stoneColumns: [],
		pearlColumns: [],
		diamondColumns: [],
		
	},
	//处理数据的计算或派生，可以理解为vue的computed的功能
	getters: {},
	//处理同步操作
	mutations: {
		TEMPLATECOLUMNS(state) {
			state.stoneColumns = [
				{
					title: '印记',
					data: 'mark',
					field: 'mark',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_mark',
					sourceCode: '',
				},{
					title: '石重字印',
					data: 'markStoneWeight',
					field: 'mark_stone_weight',
					type: 'text',
					dictCode: '',
					sourceCode: '',
				},{
					title: 'Identification',
					data: 'identification',
					field: 'identification',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_identification',
					source: 'identificationSource',
				},{
					title: 'Species',
					data: 'species',
					field: 'species',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_species',
					source: 'speciesSource',
				},{
					title: 'Gemclass',
					data: 'gemClass',
					field: 'gem_class',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_gemclass',
					source: 'gemclassSource',
				},{
					title: '*Weight',
					data: 'weight',
					field: 'weight',
					type: 'numeric',
					dictCode: '',
					sourceCode: '',
				},{
					title: '称重备注',
					data: 'weightDesc',
					field: 'weight_desc',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_weightDesc',
					source: 'weightDescSource',
				},{
					title: 'Measurements',
					data: 'measurements',
					field: 'measurements',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_measurements',
					source: 'measurementsSource',
				},{
					title: 'Item',
					data: 'item',
					field: 'item',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_item',
					source: 'itemSource',
				},{
					title: 'Cut',
					data: 'cut',
					field: 'cut',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_cut',
					source: 'cutSource',
				},{
					title: 'Shape',
					data: 'shape',
					field: 'shape',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_shape',
					source: 'shapeSource',
				},{
					title: '*Color',
					data: 'color',
					field: 'color',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_color',
					source: 'colorSource',
				},{
					title: 'Comment',
					data: 'comment',
					field: 'comment',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_comments',
					source: 'commentSource',
				},{
					title: 'Origin',
					data: 'origin',
					field: 'origin',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_origin',
					source: 'originSource',
				},{
					title: '小产地',
					data: 'smallOrigin',
					field: 'small_origin',
					type: 'text',
					dictCode: '',
					source: '',
				},{
					title: '产地前缀',
					data: 'originPrefix',
					field: 'origin_prefix',
					type: 'text',
					dictCode: '',
					source: '',
				},{
					title: '商称',
					data: 'businessCall',
					field: 'business_call',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_businessCall',
					source: 'businessCallSource',
				},{
					title: '商称简写',
					data: 'cpName',
					field: 'cp_name',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_businessCallJX',
					source: 'businessCallJXSource',
				},{
					title: '配石',
					data: 'peishi',
					field: 'peishi',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_peishi',
					source: 'peishiSource',
				},{
					title: '石重',
					data: 'stoneWeight',
					field: 'stone_weight',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_stoneWeight',
					source: 'stoneWeightSource',
				},{
					title: 'Fluorescence',
					data: 'fluorescence',
					field: 'fluorescence',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_fluorescence',
					source: 'fluorescenceSource',
				},{
					title: '成品尺寸',
					data: 'size',
					field: 'size',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_finishedSize',
					source: 'finishedSizeSource',
				},{
					title: '备注3',
					data: 'commentThree',
					field: 'comment_three',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_comment3',
					source: 'commentThreeSource',
				},{
					title: '特殊备注',
					data: 'description',
					field: 'description',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_specialRemark',
					source: 'specialRemarkSource',
				}
			];
			state.pearlColumns = [
				{
					title: '印记Marking',
					data: 'mark',
					field: 'mark',
					type: 'autocomplete',
					dictCode: '',
					sourceCode: 'markSource',
				},{
					title: '石重字印',
					data: 'markStoneWeight',
					field: 'mark_stone_weight',
					type: 'text',
					dictCode: '',
					sourceCode: '',
				},{
					title: '珍珠类型(EN)',
					data: 'typeEn',
					field: 'type_en',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlType',
					sourceCode: 'pearlTypeSource',
				},{
					title: '珍珠类型(CN)',
					data: 'typeCn',
					field: 'type_cn',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlTypeName',
					sourceCode: 'pearlTypeCnSource',
				},{
					title: '定名Variety',
					data: 'identification',
					field: 'identification',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlVariety',
					sourceCode: 'pearlVarietySource',
				},{
					title: '母贝类型Mollusk',
					data: 'mollusk',
					field: 'mollusk',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlMollusk',
					sourceCode: 'pearlMolluskSource',
				},{
					title: '饰品类型Description',
					data: 'descriptionType',
					field: 'description_type',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlDescription',
					sourceCode: 'pearlDescriptionSource',
				},{
					title: '*重量Weight',
					data: 'weight',
					field: 'weight',
					type: 'numeric',
					dictCode: '',
					sourceCode: '',
				},{
					title: '重量单位Weight Class',
					data: 'weightDesc',
					field: 'weight_desc',
					type: 'autocomplete',
					dictCode: '',
					sourceCode: 'weightDescSource',
				},{
					title: '尺寸Dimensions',
					data: 'size',
					field: 'size',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlSize',
					sourceCode: 'pearlSizeSource',
				},{
					title: '体色Color',
					data: 'bodyColor',
					field: 'body_color',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlBodyColor',
					sourceCode: 'pearlBodyColorSource',
				},{
					title: '伴色Overtone',
					data: 'overtone',
					field: 'overtone',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlOvertone',
					sourceCode: 'pearlOvertoneSource',
				},{
					title: '形状Shape',
					data: 'shape',
					field: 'shape',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlShape',
					sourceCode: 'pearlShapeSource',
				},{
					title: '光泽Luster Grade',
					data: 'lusterGrade',
					field: 'luster_grade',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlLusterGrade',
					sourceCode: 'pearlLusterGradeSource',
				},{
					title: 'Nacre Thickness',
					data: 'nacreThickness',
					field: 'nacre_thickness',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlNacreThickness',
					sourceCode: 'pearlNacreThicknessSource',
				},{
					title: '商称',
					data: 'businessCall',
					field: 'business_call',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlBusinessCall',
					sourceCode: 'pearlBusinessCallSource',
				},{
					title: '商称中文',
					data: 'businessCallCn',
					field: 'business_call_cn',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlTradeNameCN',
					sourceCode: 'pearlTradeNameCnSource',
				},{
					title: '级别Grade',
					data: 'grade',
					field: 'grade',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlGrade',
					sourceCode: 'pearlGradeSource',
				},{
					title: '配石',
					data: 'peishi',
					field: 'peishi',
					type: 'autocomplete',
					dictCode: '',
					sourceCode: 'pearlPeishiSource',
				},{
					title: '商品名称',
					data: 'cpName',
					field: 'cp_name',
					type: 'autocomplete',
					dictCode: '',
					sourceCode: '',
				},{
					title: '备注Comments',
					data: 'comment',
					field: 'comment',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlComment',
					sourceCode: 'pearlCommentOneSource',
				},{
					title: '备注1',
					data: 'commentOne',
					field: 'comment_one',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlComment1',
					sourceCode: 'pearlCommentOneSource',
				},{
					title: '备注2',
					data: 'commentTwo',
					field: 'comment_two',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlComment2',
					sourceCode: 'pearlCommentTwoSource',
				},{
					title: '备注3',
					data: 'commentThree',
					field: 'comment_three',
					type: 'autocomplete',
					dictCode: 'grc_cert_input_pearlComment3',
					sourceCode: 'pearlCommentThreeSource',
				}
			];
			state.diamondColumns = [
				{
					title: '*IdentificationResult',
					data: 'identification',
					field: 'identification',
					dictCode: '',
					sourceCode: '',
				},{
					title: 'Jewelry Type',
					data: 'typeEn',
					field: 'type_en',
					dictCode: '',
					sourceCode: '',
				},
			];
			
			
		}
	},
	//处理异步操作
	actions: {
		getInputLinkData(state) {
			
		}
	}
}